import L from "leaflet";
import { carIcon } from "../../assets/index";
import { movingTruck,bus_green,car_green,bike_green,Camper_green,Lorry_green,train_topview } from "../../assets";
import LocalShipping from "@material-ui/icons/LocalShipping";
const iconCar = new L.Icon({
  iconUrl: movingTruck,
  iconRetinaUrl: movingTruck,
  popupAnchor: [-0, -0],
  iconColor: "blue",
  iconSize: new L.Point(30, 30),
  // className: 'leaflet-div-icon'
});
const iconMarker = new L.divIcon({
  html: (
    <div>
      <LocalShipping
        style={{
          width: 40,
          height: 40,
          margin: 5,
          color: "#000",
        }}
      />
    </div>
  ),
  // bgPos?: PointExpression | undefined;
  iconSize: new L.Point(30, 30),
  // iconAnchor?: PointExpression | undefined;
  // popupAnchor?: PointExpression | undefined;
  className: "leaflet-div-icon",
});
function SetDivIcon(rotationAngle,vehicleType) {
  let vehicleImage = movingTruck; // Default icon
  if (vehicleType === 41 ) {
    vehicleImage = car_green;
  } else if (vehicleType ===47 ) {
    vehicleImage = bus_green;
  } else if (vehicleType === 44) {
    vehicleImage = bike_green;
  } else if (vehicleType === 10) {
    vehicleImage = Lorry_green;
  }else if (vehicleType === 105) {
    vehicleImage = train_topview;
  }else if (vehicleType === 110) {
    vehicleImage = Camper_green;
  }else{
    vehicleImage = movingTruck
  }
  const largeIcons = [10, 41, 44, 47, 110, 105];
  const iconSize = largeIcons.includes(vehicleType) ? "30px" : "50px";
  return {
    html: `<div style='transform: ${rotationAngle}; background: none; border: none; width: 100%; height: 100%; margin-top: -15px !important;textAlign:center'>
            <img src='${vehicleImage}' style='position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: ${iconSize}' />
        </div>`,
    className: "",
  };
}
export default SetDivIcon;
